import { faSave, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button, Form as BSForm, FormGroup, Tabs, Tab } from "react-bootstrap";
import Request from "../../../request";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { AppContext } from "../../../context";

class UsuarioSetorForm extends React.Component {
  static contextType = AppContext;

  state = {
    semUsuario: false,
  };

  save(values, callback) {
    const method = "POST";
    const url = this.context.config.BACKEND_URL + "/observacao";

    // Enviar apenas os campos necessários
    const dataToSend = {
      usuario_entrando_nome: values.usuario_entrando_nome,
      usuario_entrando_login: values.usuario_entrando_login, // CPF
      usuario_entrando_email: values.usuario_entrando_email,
      usuario_entrado_setor: values.usuario_entrado_setor,
      usuario_entrado_orgao: values.usuario_entrado_orgao,
      observacao: values.observacao,
      telefone: values.telefone,
      id: values.id,
    };

    Request(method, url, this.context.token)
      .send(dataToSend)
      .then(() => {
        this.context.addToast({
          titulo: "Sucesso",
          conteudo: "Registro atualizado com sucesso.",
        });
        callback();
        if (this.props.onSave) this.props.onSave();
        this.context.closeModal();
      })
      .catch(() => {
        this.context.addToast({
          titulo: "Erro",
          conteudo: "Houve uma falha na gravação do registro.",
        });
        callback();
      });
  }

  handleCheckboxChange = () => {
    this.setState((prevState) => {
      const semUsuario = !prevState.semUsuario;
  
      if (semUsuario && this.props.observacao?.message) {
        window.alert(
          "Você é o último usuário com acesso ao sistema Disque Denúncia. Deseja realmente prosseguir sem incluir outro usuário?"
        );
      }
  
      return { semUsuario }; // Atualizar o estado
    });
  };
  

  render() {
    const { semUsuario } = this.state;

    return (
      <Formik
        initialValues={{
          ...this.props.observacao.usuario,
          usuario_entrando_nome: "",
          usuario_entrando_login: "",
          usuario_entrando_email: "",
          telefone: "",
          observacao: "sim", // Ajuste aqui
          idUsuario: this.props.observacao.id,
        }}
        validate={(values) => {
          const errors = {};
          if (values.semUsuario === false) {
            if (!values.usuario_entrando_nome) {
              errors.usuario_entrando_nome = "Campo obrigatório";
            }
            if (!values.usuario_entrando_login) {
              errors.usuario_entrando_login = "Campo obrigatório";
            }
            if (!values.usuario_entrando_email) {
              errors.usuario_entrando_email = "Campo obrigatório";
            }
            
          }
          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          this.save(values, () => setSubmitting(false));
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <Tabs defaultActiveKey="usuario_saindo" className="mb-2">
              <Tab eventKey="usuario_saindo" title="Usuario Saindo">
                <FormGroup>
                  <BSForm.Label>Nome</BSForm.Label>
                  <ErrorMessage
                    name="nome"
                    component="span"
                    className="text-danger small ml-2"
                  />
                  <Field
                    type="text"
                    name="nome"
                    className="form-control"
                    disabled
                  />
                </FormGroup>
                <FormGroup>
                  <BSForm.Label>Login</BSForm.Label>
                  <ErrorMessage
                    name="login"
                    component="span"
                    className="text-danger small ml-2"
                  />
                  <Field
                    type="text"
                    name="login"
                    className="form-control"
                    disabled
                  />
                </FormGroup>
                <FormGroup>
                  <BSForm.Label>Email</BSForm.Label>
                  <ErrorMessage
                    name="email"
                    component="span"
                    className="text-danger small ml-2"
                  />
                  <Field
                    type="email"
                    name="email"
                    className="form-control"
                    disabled
                  />
                </FormGroup>
              </Tab>
              <Tab eventKey="usuario_entrando" title="Usuario Entrando">
                <FormGroup>
                  <Field
                    type="checkbox"
                    name="semUsuario"
                    className="text-right"
                    checked={this.state.semUsuario} // Vincula ao estado
                    onChange={this.handleCheckboxChange} // Atualiza o estado ao alterar
                  />
                  <BSForm.Label as="b" style={{ marginLeft: "10px" }}>
                    Sem usuario para adicionar no sistema 181
                  </BSForm.Label>
                 
                </FormGroup>
                <FormGroup>
                  <BSForm.Label>Nome</BSForm.Label>
                  <ErrorMessage
                    name="usuario_entrando_nome"
                    component="span"
                    className="text-danger small ml-2"
                    disabled={semUsuario} // Desabilita o campo se semUsuario estiver ativo
                  />
                  <Field
                    type="text"
                    name="usuario_entrando_nome"
                    className="form-control"
                    required
                    placeholder="Nome completo"
             
                    disabled={semUsuario}
                  />
                </FormGroup>
                <FormGroup>
                  <BSForm.Label>Login (CPF)</BSForm.Label>
                  <ErrorMessage
                    name="usuario_entrando_login"
                    component="span"
                    className="text-danger small ml-2"
                  />
                  <Field
                    type="text"
                    name="usuario_entrando_login"
                    className="form-control"
                    required
                    placeholder="CPF sem pontuação"
                   
                    disabled={semUsuario}
                  />
                </FormGroup>
                <FormGroup>
                  <BSForm.Label>Email</BSForm.Label>
                  <ErrorMessage
                    name="usuario_entrando_email"
                    component="span"
                    className="text-danger small ml-2"
                  />
                  <Field
                    type="email"
                    name="usuario_entrando_email"
                    className="form-control"
                    required
                    placeholder="email"
                 
                    disabled={semUsuario}
                  />
                </FormGroup>
                <FormGroup>
                  <BSForm.Label>Telefone</BSForm.Label>
                  <ErrorMessage
                    name="telefone"
                    component="span"
                    className="text-danger small ml-2"
                  />
                  <Field
                    type="text"
                    name="telefone"
                    className="form-control"
                    required
                    placeholder="(xx)9xxxx-xxxx"
                
                    disabled={semUsuario}
                  />
                </FormGroup>
              </Tab>
            </Tabs>
            <FormGroup className="text-right">
              <Button
                type="button"
                variant="secondary"
                onClick={() => this.context.closeModal()}
              >
                <FontAwesomeIcon icon={faTimes} />
                &nbsp; Fechar
              </Button>
              <Button
                type="submit"
                disabled={isSubmitting}
                variant="primary"
                className="ml-2"
              >
                <FontAwesomeIcon icon={faSave} />
                &nbsp; Gravar Alterações
              </Button>
            </FormGroup>
          </Form>
        )}
      </Formik>
    );
  }
}

export default UsuarioSetorForm;
