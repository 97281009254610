import React from 'react';
import { AppContext } from '../../../context';
import ModelList from '../ModelList';

class UsuarioDisqueDenunciaSetorList extends React.Component {
  static contextType = AppContext;

  render() {
    return (
        <ModelList
          url={this.context.config.BACKEND_URL + '/usuario/disquedenuncia'}
          title="Lista de Observações"
          modelIcon="faUsers"
          modelName="usuario"
          onAction={false}
        />
    );
  }
}

export default UsuarioDisqueDenunciaSetorList;
